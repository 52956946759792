// @ts-nocheck
import { EConsents } from '~/types/usercentrics'

export const useFlashTalking = () => {
  const { hasConsent } = useConsent(EConsents.Flashtalking)

  const initTalkingTag = (enquiryId, spotName) => {
    if (!hasConsent.value) {
      return
    }

    const ftRandom = Math.random() * 1000000

    if (document.location.protocol === 'https:') {
      const iframe = document.createElement('iframe')
      iframe.style.display = 'none'
      iframe.src = `https://servedby.flashtalking.com/container/13459;${enquiryId};11552;iframe/?spotName=${spotName}&cachebuster=${ftRandom}`
      document.body.appendChild(iframe)
    }
  }

  const initOneTag = () => {
    if (!hasConsent.value) {
      return
    }

    const ftOneTag11691 = {
      ft_vars: {
        ftXRef: '',
        ftXValue: '',
        ftXType: '',
        ftXName: '',
        ftXNumItems: '',
        ftXCurrency: '',
        U1: '',
        U2: '',
        U3: '',
        U4: '',
        U5: '',
        U6: '',
        U7: '',
        U8: '',
        U9: '',
        U10: '',
        U11: '',
        U12: '',
        U13: '',
        U14: '',
        U15: '',
        U16: '',
        U17: '',
        U18: '',
        U19: '',
        U20: '',
        U21: '',
        U22: '',
        U23: '',
        U24: '',
        U25: '',
        U26: '',
        U27: '',
        U28: '',
        U29: '',
        U30: '',
        U31: '',
        U32: '',
        U33: '',
        U34: '',
        U35: '',
        U36: '',
        U37: '',
        U38: '',
        U39: '',
        U40: ''
      },
      ot_dom: 'https://servedby.flashtalking.com',
      ot_path: '/container/13459;112389;11691;iframe/?',
      ot_href: 'ft_referrer=' + escape(document.location.href),
      ot_rand: Math.random() * 1000000,
      ot_ref: document.referrer,
      ot_init() {
        let qs = ''
        let count = 0
        let ns = ''
        for (const key in this.ft_vars) {
          qs +=
            this.ft_vars[key] === '' ? '' : key + '=' + this.ft_vars[key] + '&'
        }
        count =
          this.ot_path.length +
          qs.length +
          this.ot_href +
          escape(this.ot_ref).length
        ns = this.ot_ns(count - 2000)
        const ifrm = document.createElement('iframe')
        ifrm.style.cssText =
          'width:1px;height:1px;position:absolute;display:none;'
        ifrm.src =
          this.ot_dom +
          this.ot_path +
          qs +
          this.ot_href +
          '&ns=' +
          ns +
          '&cb=' +
          this.ot_rand
        document.body.appendChild(ifrm)
      },
      ot_ns(diff) {
        if (diff > 0) {
          const qo = {}
          const sp = /(?:^|&)([^&=]*)=?([^&]*)/g
          // eslint-disable-next-line no-useless-escape
          const fp = /^(http[s]?):\/\/?([^:\/\s]+)\/([\w.]+[^#?\s]+)(.*)?/.exec(
            this.ot_ref
          )
          const ro = {
            h: fp[2],
            p: fp[3],
            qs: fp[4].replace(sp, function (p1, p2, p3) {
              if (p2) qo[p2] = [p3]
            })
          }
          return escape(
            ro.h +
              ro.p.substring(0, 10) +
              (qo.q ? '?q=' + unescape(qo.q) : '?p=' + unescape(qo.p))
          )
        } else {
          return escape(unescape(this.ot_ref))
        }
      }
    }

    ftOneTag11691.ot_init()
  }

  return {
    initOneTag,
    initTalkingTag
  }
}
